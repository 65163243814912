import moment from "moment";
import NotifyMixin from "@/mixins/NotifyMixin";

export default {
    name: "ProfilesMixin",
    mixins: [NotifyMixin],
    computed: {
        customerId: function () {
          return this.$route.params.customerId;
        }
    },
    data: function () {
        return {
          persons: [],
          memberships: [],
          leases: [],
        };
    },
    methods: {
        formatLeaseLabel({ deletedAt, locationName, type, startDate, endDate}) {
            return `${deletedAt ? '(deleted at ' + this.formatDate(deletedAt) + ') ' : ''}${locationName} - ${type} - ${this.formatDate(startDate)}-${this.formatDate(endDate)}`;
        },
        formatPersonLabel({ deletedAt, firstName, lastName }) {
            return `${deletedAt ? '(deleted at ' + this.formatDate(deletedAt) + ') ' : ''}${firstName} ${lastName}`;
        },
        fetchPersons() {
          this.$raaDataProvider.getList('persons', {size: 999})
            .then(({content}) => {
              this.persons = content.map((item) => ({
                      key: item.id,
                      value: this.formatPersonLabel(item),
                  }));
            })
            .catch((err) => this.notifyError(err.message));
        },
        fetchMemberships() {
          this.$raaDataProvider.getList('memberships', {size: 999})
            .then(({content}) => {
              this.memberships = content.map(({id, partyName, communityName, engagementDate, disEngagementDate, deletedAt}) => ({
                      key: id,
                      value: `${deletedAt ? '(deleted at '+this.formatDate(deletedAt)+') ' : ''}${partyName} - ${communityName}, ${this.formatDate(engagementDate)}-${this.formatDate(disEngagementDate)}`,
                  }));
            })
            .catch((err) => this.notifyError(err));
        },
        fetchLeases() {
          this.$raaDataProvider.getList('leases', {size: 999})
            .then(({content}) => {
              this.leases = content.map((item) => {
                   return {
                      ...item,
                      key: item.id,
                      value: this.formatLeaseLabel(item),
                  };
              });
            })
            .catch((err) => this.notifyError(err));
        },
        formatDate(date) {
            return date ? moment(date).format('LL') : 'present';
        },
    }
}
